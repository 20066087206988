import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReasignarSlotDto } from '../models/ventana-config.model';
import { ResponseBackAzure } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class VentanasPagosContratosService {
  protected _http = inject(HttpClient);
  private readonly httpParam = new HttpParams().append('backend', 'azure');

  private readonly urlBase = 'contratos/ventas/ventana';

  getConfiguracion() {
    return this._http.get<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/pagos`, {
      params: this.httpParam,
    });
  }

  postConfiguracion(data: any) {
    return this._http.post<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/pagos`, data, {
      params: this.httpParam,
    });
  }

  putConfiguracion(data: any) {
    return this._http.put<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/pagos`, data, {
      params: this.httpParam,
    });
  }

  deleteConfiguracion(id: string) {
    return this._http.delete<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/pagos/${id}`, {
      params: this.httpParam,
    });
  }

  asignarSlot(id_contrato: string) {
    return this._http.post<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/${this.urlBase}/pago/slot`,
      { id_contrato },
      {
        params: this.httpParam,
      }
    );
  }

  reassignSlot(body: ReasignarSlotDto) {
    return this._http.put<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/slot`, body, {
      params: this.httpParam,
    });
  }

  getTablaControl() {
    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/${this.urlBase}/tabla-control?origen=SOLICITUD_PAGO`,
      {
        params: this.httpParam,
      }
    );
  }

  getVentanasData(fecha_inicio: string, diasAdelante: number) {
    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/${this.urlBase}/slot?origen=SOLICITUD_PAGO&fecha_inicio=${fecha_inicio}&dias_adelante=${diasAdelante}`,
      {
        params: this.httpParam,
      }
    );
  }

  getDataAdminVentanas(fecha_inicio: string, diasAdelante: number) {
    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/${this.urlBase}/slot/detalle?origen=SOLICITUD_PAGO&fecha_inicio=${fecha_inicio}&dias_adelante=${diasAdelante}`,
      {
        params: this.httpParam,
      }
    );
  }
}
